/* global rp$, window, grecaptcha */

var THE_SCRIPT = function () {

	const $onReady = require('public/js/lib/on-ready');

	const forgotPasswordFormId      = '#forgot-password-form';
	const forgotPasswordButtonClass = '.forgot-password-form-submit';

	window.getForgotPasswordCaptchaToken = function(token) {
		forgotPasswordFormSubmit();
	}

	function forgotPasswordFormSubmit() {
		rp$(forgotPasswordFormId).submit();
	}

	$onReady({
		rp$,
		window,
		label: 'forgot_password',
		fn: function () {
			rp$(forgotPasswordButtonClass).on('click', function (evt) {
				evt.preventDefault();
				if(rp$(this).hasClass('disabled')) {
					return;
				}

				if(typeof grecaptcha === 'object') {
					var captchaIndex = -1;
					rp$('.g-recaptcha').each(function(index) {
						if(rp$(this)[0].id === 'forgot-password-form-captcha') {
							captchaIndex = index;
						}
					});
					grecaptcha.execute(captchaIndex);
				} else {
					forgotPasswordFormSubmit();
				}
			});
		},
	});
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
